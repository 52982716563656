import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const Home = () => import('../views/Web/Home.vue')
const Case = () => import('../views/Web/Case.vue')
const About = () => import('../views/Web/About.vue')
const Contact = () => import('../views/Web/Contact.vue')
const Job = () => import('../views/Web/Job.vue')

const Mi = () => import('../views/Mi/Mi.vue')
const mHome = () => import('../views/Mi/mHome.vue')
const mCase = () => import('../views/Mi/mCase.vue')
const mAbout = () => import('../views/Mi/mAbout.vue')
const mContact = () => import('../views/Mi/mContact.vue')
const mJob = () => import('../views/Mi/mJob.vue')



const routes = [
  // {
  //   path:'/web',
  //   redirect:'/home'
  // },
  {
    path:'/mi',
    redirect:'/mhome'
  },
  {
    // path: '/web',
    path: '/',
    name: 'Web',
    component: () => import('../views/Web/Web.vue'),
    children:[
      {
        // path: '/home',
        path: '/',
        name: 'Home',
        component:  Home
      },
      {
        path: '/about',
        name: 'About',
        component: About
      }
      ,
      {
        path: '/case',
        name: 'Case',
        component:  Case
      }
      ,
      {
        path: '/contact',
        name: 'Contact',
        component: Contact
      }
      ,
      {
        path: '/job',
        name: 'Job',
        component: Job
      }
    ]
  },
  {
    path: '/mi',
    name: 'Mi',
    component:  Mi,
    children:[
      {
        path: '/mhome',
        name: 'mHome',
        component:  mHome
      },
      {
        path: '/mabout',
        name: 'mAbout',
        component: mAbout
      }
      ,
      {
        path: '/mCase',
        name: 'mCase',
        component: mCase
      }
    ,  
      {
        path: '/mJob',
        name: 'mJob',
        component: mJob
      }
      ,  
      {
        path: '/mContact',
        name: 'mContact',
        component: mContact
      }
    ]
  },
  
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
