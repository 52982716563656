<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import DevicePixelRatio from './utils/rem1'

export default {
  created() {
    new DevicePixelRatio().init()
  },
  methods: {
    // _isMobile() {
    //   let flag = navigator.userAgent.match(/(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    //   return flag;
    // },
    // _isIpad() {
    //   let flag = navigator.userAgent.match(/(pad|iPad)/i)
    //   return flag;
    // },
    detect() {
      var equipmentType = " ";
      var agent = navigator.userAgent.toLowerCase();
      //android ios windows
      var android = agent.indexOf("android");
      var iphone = agent.indexOf("iphone");
      var ipad = agent.indexOf("ipad");
      var windows = agent.indexOf("windows");
      var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
      if (android != -1||iphone != -1) {
        this.$router.replace('/mi');
        return
      }
      if ( ipad != -1) {// || isMac
        this.$router.replace('/mi');//ipad
        return
      }
      if (windows != -1 || isMac) {
        // this.$router.replace('/web');
        this.$router.replace('/');
        return
      }
      return equipmentType;
    }

  },
  mounted() {
    // if (this._isMobile()) {
    //   this.$router.replace('/mi');
    // } else {
    //   if (this._isIpad()) {
    //   this.$router.replace('/ipad');
    // }else{
    //   this.$router.replace('/home');
    // }

    // }
    this.detect()
  }
}

</script>
<style>
@font-face {
  font-family: 'SourceHanSansCN-Regular';
  src: url('./assets/font/SOURCEHANSANSCN-REGULAR.OTF');
}
@font-face {
  font-family: 'SourceHanSansCN-Bold';
  src: url('./assets/font/SOURCEHANSANSCN-BOLD.OTF');
}
#app {
  /* min-width: 1920px; */
  width: 100%;
  height: 100%;
}
* {
  padding: 0;
  margin: 0;
}

image,img{
		image-rendering: -moz-crisp-edges;
		image-rendering: -o-crisp-edges;
		image-rendering: -webkit-optimize-contrast;
		image-rendering: crisp-edges;
		-ms-interpolation-mode: nearest-neighbor;
	}
  input::-webkit-input-placeholder, textarea::-webkit-input-placeholder { color: #C6C6C6;font-weight: 500; }
input:-moz-placeholder, textarea:-moz-placeholder { color: #C6C6C6;font-weight: 500; }
input::-moz-placeholder, textarea::-moz-placeholder { color: #C6C6C6;font-weight: 500; }
input:-ms-input-placeholder, textarea:-ms-input-placeholder { color: #C6C6C6;font-weight: 500; }
</style>
