import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/font/font.css';//字体
import './utils/rem'//自适应配置
// 引入ElementUI组件库
import ElementUI from 'element-ui';
//引入ElementUI的所有样式
import 'element-ui/lib/theme-chalk/index.css';
import AMap from 'vue-amap'; //地图
import VueLazyload from "vue-lazyload";//图片懒加载
Vue.use(VueLazyload);

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(AMap);


import animated from 'animate.css'
Vue.use(animated)

 // 初始化vue-amap
 AMap.initAMapApiLoader({
  // 高德key
  key: 'e9c6fcb86cc26b2767905d4850e79348',
  // 插件集合 （插件按需引入）
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  v: '1.4.4' // 默认高德 sdk 版本为 1.4.4
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
